import { routingRuleApi } from "@/api";

/**
 *  Obtener las conexiones
 */
export const getUsers = async ({ commit , rootState },params) => {
    let code = 200;
    try {
        commit('setLoading', true);
        const queryString = Object.entries(params)
                            .filter(([key, value]) => value !== '')
                            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                            .join('&');
        const { data } = await routingRuleApi.get(`/api/v1/users?${queryString}`, rootState.auth.config);
        const users = data.users.data.map( users => {
            const { id, name, last_name, email, cuit, active } = users;
            
            let name_full = `${name} ${(last_name != null) ? last_name : '' }`
            let cuitReturn = (cuit) ? cuit : "---"

            return {id, "name":name_full , email, "cuit":cuitReturn, active };
        });

        commit('setUsers', users );
        commit('setLoading', false);

        return {
            ok: true,
            total: data.users.total
        }
    } catch (error) {
        commit('setLoading', false);
        code = (error.response && error.response.status) ? error.response.status : 500;

        if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('401 Unauthorized')){
            code = 401;
        }

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener los usuarios.'
        }
    }
}

/**
 *  Obtener un usuario
 */
export const getUser = async ({ commit, rootState }, id) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.get(`/api/v1/users/${id}`,rootState.auth.config);

        commit('setLoading', false);
        commit('setUser', data.user);
        return {
            ok: true,
            message:' Se obtuvo correctamente el usuario ',
            user:data.user
        }
    } catch (error) {
        commit('setLoading', false);

        return {
            ok: false,
            message: `No se pudo obtener el usuario ${id}`
        }
    }
}


/**
 *  Actualizar un usuario
 */
export const updateUser = async ({ commit, rootState }, dataUpdate) => {
    try {
        commit('setLoading', true);
        const {id, dataForm, profile} = dataUpdate;

        delete dataForm.id;
        delete dataForm.created_at;
        delete dataForm.updated_at;
        delete dataForm.deleted_at;
        delete dataForm.email_verified_at;
        const { data } = await routingRuleApi.put(`/api/v1/users/${id}?profile=${profile}`, dataForm, rootState.auth.config);

        commit('setLoading', false);
        commit('setUser', data.user);


        return {
            ok: true,
            message:" Se actualizó el usuario correctamente ",
            data
        }
    } catch (error) {
        let errors = [];
        let message = 'No se pudo actualizar el usuario, reintente luego...';
        if (error.response.data.message) {
            message = error.response.data.message;
        }
        if(error.response && error.response.data && error.response.data.errors){
            errors = error.response.data.errors;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message,
            errors
        }
    }
}

/**
 *  Desactivar un usuario
 */
export const deactiveOrActiveUser = async ({ commit, rootState }, dataForm) => {
    try {
        const {id, value} = dataForm;
        commit('setLoading', true);
        const { data } = await routingRuleApi.put(`/api/v1/users/delete/${id}?action=${value}`, [], rootState.auth.config);

        commit('setLoading', false);

        let message = " Se desactivo el usuario correctamente "
        if(value == 'active'){
            message = " Se activo el usuario correctamente "
        }

        return {
            ok: true,
            message:message,
            data
        }
    } catch (error) {
        let message = 'No se pudo desactivar el usuario, reintente luego...';
        if (error.response?.data.message) {
            message = error.response.data.message;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message
        }
    }
}