import { createRouter, createWebHistory } from 'vue-router';
import routeUsers from '@/modules/users/router';
import routeProducts from '@/modules/products/router';
import routeMovements from '@/modules/movements/router';
import routeRecords from '@/modules/records/router';
import routePharmacies from '@/modules/pharmacies/router';
const Login = () => import('@/modules/auth/views/LoginView.vue');
const Register = () => import('@/modules/auth/views/RegisterView.vue');
const VerificationCode = () => import('@/modules/auth/views/VerificationCodeView.vue');
const RecoveryPassword = () => import('@/modules/auth/views/RecoveryPasswordView.vue');
const ResetPasswordView = () => import('@/modules/auth/views/ResetPasswordView.vue');
const Home = () => import('@/views/HomeView.vue');

const routes = [
    {
        path: '/',
        name: "Login",
        component: Login,
        meta: {
            public: true,
            disableIfLoggedIn: true,
            title:' FarmaDigit ',
            in_menu: false
        }
    },
    {
        path: '/register',
        name: "Register",
        component: Register,
        meta: {
            disableIfLoggedIn: true,
            public: true,
            title:' Registrarme ',
            in_menu: false
        }
    },
    {
        path: '/verification-code',
        name: "VerificacionCodigo",
        component: VerificationCode,
        meta: {
            disableIfLoggedIn: true,
            public: true,
            title:' Verificar Codigo ',
            in_menu: false
        }
    },
    {
        path: '/recovery-password',
        name: "RecuperarContrasena",
        component: RecoveryPassword,
        meta: {
            disableIfLoggedIn: true,
            public: true,
            title:' Recuperar Contrase�a ',
            in_menu: false
        }
    },
    {
        path: '/reset-password',
        name: "CambiarContrasena",
        component: ResetPasswordView,
        meta: {
            disableIfLoggedIn: true,
            public: true,
            title:' Cambiar Contrase�a ',
            in_menu: false
        }
    },
    {
        path: '/home',
        name: "Inicio",
        component: Home,
        meta: {
            in_menu: true,
            icon: 'fas fa-home',
            requiresAuth: true,
            public: false,
            title:' Inicio ',
            Miniumprofile: 'users'
        }
    },
    ...routeUsers,
    ...routeProducts,
    ...routePharmacies,
    ...routeMovements,
    ...routeRecords,
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    if (!to.meta.public) {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (localStorage.getItem("FD-Token") == null && to.query.token == null) {
                next({
                    path: "/",
                    params: { nextUrl: to.fullPath },
                });
            } else {
                next();
            }
        }
    } else {
        next();
    }
});


export default router;